<template>
  <div class="resource-table">
    <v-data-table
      :headers="headersFromProps"
      :items="records"
      class="elevation-1"
      :loading="loading"
      :loading-text="`loading ${resource}`"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="`${resource}/${item.id}/edit`"
              class="resource-table__link"
            >
              <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                fas fa-pencil-alt
              </v-icon>
            </router-link>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="showDeleteConfirmation(item.id)"
              v-bind="attrs"
              v-on="on"
            >
              fas fa-trash
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        No data
      </template>
    </v-data-table>
    <ConfirmDialog
      v-model="deleteAlertVisible"
      @handleClose="deleteAlertVisible = false"
      @handleConfirm="deleteResource"
    />
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    deleteSuccessMessage: {
      type: String,
      default: "Successfuly deleted!"
    },
    renderId: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true,
      deleteAlertVisible: false,
      headersFromProps: this.renderId
        ? [
            {
              text: "ID",
              value: "id"
            },
            ...this.headers.concat({
              text: "Actions",
              value: "actions",
              sortable: false
            })
          ]
        : this.headers.concat({
            text: "Actions",
            value: "actions",
            sortable: false
          }),
      selectedId: null,
      records: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let generatedRoute = `/${this.resource}`;
      this.getList(this.resource)
        .then(response => {
          this.records = response.data;
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
        });
    },

    showDeleteConfirmation(id) {
      this.selectedId = id;
      this.deleteAlertVisible = true;
    },

    deleteResource() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.delete(this.resource, { id: this.selectedId })
        .then(() => {
          this.loading = false;
          this.deleteAlertVisible = false;

          this.records = this.records.filter(
            item => item.id !== this.selectedId
          );

          this.selectedId = null;

          this.$toasted.show(this.deleteSuccessMessage, {
            type: "success"
          });
        })
        .catch(err => {
          this.loading = false;
          this.deleteAlertVisible = false;
          this.selectedId = null;

          this.$toasted.show(err.message, {
            type: "error"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-table {
  &__link {
    &,
    &:visited {
      text-decoration: none;
    }
  }
}
</style>
