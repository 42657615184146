var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delete-confirm" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.isActive,
            callback: function($$v) {
              _vm.isActive = $$v
            },
            expression: "isActive"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v("\n        " + _vm._s(_vm.text) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.handleClose } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.cancelText) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: _vm.type },
                      on: { click: _vm.handleConfirm }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.confirmText) + "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }