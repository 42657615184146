import Login from "../panels/auth/Login.vue";
import ForgetPassword from "../panels/auth/ForgotPassword.vue";

const routes = [
  {
    path: "/",
    name: "login_page",
    components: {
      default: Login
    }
  },
  {
    path: "/forgot_password",
    name: "forgot_password_page",
    components: {
      default: ForgetPassword
    }
  }
];

export default routes;
