<template>
  <v-card class="elevation-12">
    <v-form v-model="formModel.valid" @submit="handleSubmit" ref="loginForm">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Login form</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-account"
          type="text"
          required
          v-model="formModel.email"
          :rules="formModel.requiredRules"
        />

        <v-text-field
          id="password"
          label="Password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          required
          v-model="formModel.password"
          :rules="formModel.requiredRules"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn type="submit" color="primary" :disabled="!formModel.valid">
          Login
        </v-btn>
        <v-btn :to="{ name: 'forgot_password_page' }">Forget Password</v-btn>
      </v-card-actions>
    </v-form>

    <v-snackbar v-model="snackbar" color="error" :timeout="3000">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      text: "email or password is invalid",
      formModel: {
        valid: false,
        email: "",
        password: "",
        requiredRules: [v => !!v || "required field"]
      }
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$refs.loginForm.validate();

      if (!this.formModel.valid) {
        return;
      }

      this.axios.post('/users/sign_in', { user: this.formModel })
        .then(response => {
          window.location.href = '/dashboard'
      }).catch(error => {
        console.log('error', error)
        this.snackbar = true;
      })
    }
  }
};
</script>
