var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-layout" },
    [
      _c(
        "v-banner",
        {
          attrs: { "single-line": "" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        to: { name: _vm.resource + "-create" }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "small" } }, [
                        _vm._v("fas fa-plus")
                      ]),
                      _vm._v(" Add New\n      ")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("h2", { staticClass: "table-layout__title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
          ])
        ]
      ),
      _vm._v(" "),
      _c("ResourceTable", {
        attrs: { resource: _vm.resource, headers: _vm.headers }
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }