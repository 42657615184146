import dataProvider from "../api/dataProvider";

export default {
  methods: {
    ...dataProvider,
    /**
     * send value and event in the component like this:
     * <v-btn @click="doCopy(this.message, $event)">Copy</v-btn>
     */
    doCopy(value, event) {
      this.$copyText(value, event.target).then(
        () => {
          this.$toasted.show("Successfully copied to clipboard!", {
            type: "success"
          });
        },
        () => {
          this.$toasted.show("Error happened, can not copy to clipboard", {
            type: "error"
          });
        }
      );
    },
    log() {
      console.log(...arguments);
    }
  }
};
