var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-12" },
    [
      _c(
        "v-form",
        {
          ref: "loginForm",
          on: { submit: _vm.handleSubmit },
          model: {
            value: _vm.formModel.valid,
            callback: function($$v) {
              _vm.$set(_vm.formModel, "valid", $$v)
            },
            expression: "formModel.valid"
          }
        },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "", flat: "" } },
            [_c("v-toolbar-title", [_vm._v("Login form")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Email",
                  name: "email",
                  "prepend-icon": "mdi-account",
                  type: "text",
                  required: "",
                  rules: _vm.formModel.requiredRules
                },
                model: {
                  value: _vm.formModel.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formModel, "email", $$v)
                  },
                  expression: "formModel.email"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  id: "password",
                  label: "Password",
                  name: "password",
                  "prepend-icon": "mdi-lock",
                  type: "password",
                  required: "",
                  rules: _vm.formModel.requiredRules
                },
                model: {
                  value: _vm.formModel.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formModel, "password", $$v)
                  },
                  expression: "formModel.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    color: "primary",
                    disabled: !_vm.formModel.valid
                  }
                },
                [_vm._v("\n        Login\n      ")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { to: { name: "forgot_password_page" } } }, [
                _vm._v("Forget Password")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error", timeout: 3000 },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "secondary", text: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("\n        Close\n      ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }