var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-table" },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headersFromProps,
          items: _vm.records,
          loading: _vm.loading,
          "loading-text": "loading " + _vm.resource
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "resource-table__link",
                                  attrs: {
                                    to: _vm.resource + "/" + item.id + "/edit"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n              fas fa-pencil-alt\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Edit")])]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showDeleteConfirmation(
                                            item.id
                                          )
                                        }
                                      }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n            fas fa-trash\n          "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Delete")])]
                )
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [_vm._v("\n      No data\n    ")]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("ConfirmDialog", {
        on: {
          handleClose: function($event) {
            _vm.deleteAlertVisible = false
          },
          handleConfirm: _vm.deleteResource
        },
        model: {
          value: _vm.deleteAlertVisible,
          callback: function($$v) {
            _vm.deleteAlertVisible = $$v
          },
          expression: "deleteAlertVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }