var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-12" },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Forgot Pasword form")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Email",
                  name: "email",
                  "prepend-icon": "mdi-account",
                  type: "text"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("\n      Submit\n    ")]
          ),
          _vm._v(" "),
          _c("v-btn", { attrs: { to: { name: "login_page" } } }, [
            _vm._v("login")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }