<template>
  <div class="delete-confirm">
    <v-dialog v-model="isActive" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleClose">
            {{ cancelText }}
          </v-btn>

          <v-btn :color="type" @click="handleConfirm">
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "error"
    },
    title: {
      type: String,
      default: "Warning!"
    },
    text: {
      type: String,
      default: "Are you sure?"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    confirmText: {
      type: String,
      default: "Yes"
    }
  },
  data() {
    return {
      isActive: !!this.value
    };
  },
  watch: {
    value(val) {
      this.isActive = val;
    },
    isActive(val) {
      if (!val) {
        this.$emit("handleClose");
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$emit("handleConfirm");
    },
    handleClose() {
      this.$emit("handleClose");
    }
  }
};
</script>
