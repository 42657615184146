<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat>
      <v-toolbar-title>Forgot Pasword form</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-account"
          type="text" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="submitForm">
        Submit
      </v-btn>
      <v-btn :to="{ name: 'login_page' }">login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    submitForm () {
      console.log('Submit forgot password form!');
    }
  }
}
</script>
