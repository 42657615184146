import axios from "axios";
import merge from "deepmerge";

export default {
  getList: (resource, params = {}, requestConfig = {}) => {
    return axios(
      merge(
        {
          method: "GET",
          url: `/api/v1/${resource}`,
          params: params.params
        },
        requestConfig
      )
    );
  },

  getOne: (resource, params = {}, requestConfig = {}) => {
    return axios(
      merge(
        {
          method: "GET",
          url: `/api/v1/${resource}/${params.id}`
        },
        requestConfig
      )
    );
  },

  update: (resource, params = {}, requestConfig = {}) => {
    return axios(
      merge(
        {
          method: "PUT",
          url: `/api/v1/${resource}/${params.id}`,
          data: params.data
        },
        requestConfig
      )
    );
  },

  create: (resource, params = {}, requestConfig = {}) => {
    return axios(
      merge(
        {
          method: "POST",
          url: `/api/v1/${resource}`,
          data: params.data
        },
        requestConfig
      )
    );
  },

  delete: (resource, params = {}, requestConfig = {}) => {
    return axios(
      merge(
        {
          method: "DELETE",
          url: `/api/v1/${resource}/${params.id}`
        },
        requestConfig
      )
    );
  }
};
