var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-layout" },
    [
      _c("h2", { staticClass: "mb-5" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _vm.record
        ? [
            _c("ValidationObserver", {
              ref: "form",
              attrs: { slim: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var valid = ref.valid
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "v-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.onSubmit)
                              }
                            }
                          },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.loading
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "edit-layout__form" },
                                  [
                                    _vm._t("form", null, null, {
                                      initRecord: _vm.initRecord,
                                      handleChange: _vm.handleChange,
                                      isSubmitting: _vm.loading,
                                      valid: valid,
                                      changed: _vm.changed,
                                      record: _vm.record,
                                      edit: true
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("v-divider"),
                                _vm._v(" "),
                                _c(
                                  "v-card-actions",
                                  [
                                    _vm._t("actions", [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            type: "submit",
                                            color: "primary",
                                            disabled: !_vm.changed
                                          }
                                        },
                                        [_vm._v("Update")]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._t("actions", [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "error" },
                                          on: {
                                            click: function($event) {
                                              _vm.deleteAlertVisible = true
                                            }
                                          }
                                        },
                                        [_vm._v("Delete")]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ConfirmDialog", {
                          on: {
                            handleClose: function($event) {
                              _vm.deleteAlertVisible = false
                            },
                            handleConfirm: _vm.deleteResource
                          },
                          model: {
                            value: _vm.deleteAlertVisible,
                            callback: function($$v) {
                              _vm.deleteAlertVisible = $$v
                            },
                            expression: "deleteAlertVisible"
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        : [
            _c("v-card", { attrs: { loading: "" } }, [
              _c("div", { staticStyle: { height: "25vh" } })
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }