<template>
  <div class="create-layout">
    <h2 class="mb-5">
      {{ title }}
    </h2>

    <ValidationObserver
      ref="form"
      v-slot="{ valid, handleSubmit, changed }"
      slim
    >
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-card :loading="loading" :disabled="loading">
          <div class="create-layout__form">
            <slot
              name="form"
              v-bind="{
                initRecord,
                handleChange,
                isSubmitting: loading,
                valid,
                changed,
                edit: false
              }"
            >
            </slot>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <slot name="actions">
              <v-btn type="submit" color="primary">Create</v-btn>
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    createSuccessMessage: {
      type: String,
      default: "Created successfully!"
    }
  },
  data() {
    return {
      loading: false,
      record: {}
    };
  },
  methods: {
    initRecord(record) {
      this.record = record;
    },
    handleChange(key, value) {
      this.record = this._.set(this.record, key, value);
    },
    onSubmit(value) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.create(this.resource, { data: this.record })
        .then(() => {
          this.loading = false;
          this.$toasted.show(this.createSuccessMessage, {
            type: "success"
          });
          if (window.history.length > 0) {
            this.$router.back();
          } else {
            this.$router.push({
              name: `${this.resource}-list`
            });
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.status === 422) {
            this.$refs.form.setErrors(err.response.data.error);
          } else {
            this.$toasted.show(err.message, {
              type: "error"
            });
          }
        });
    }
  }
};
</script>
