<template>
  <div class="table-layout">
    <v-banner single-line>
      <h2 class="table-layout__title">
        {{ title }}
      </h2>

      <template v-slot:actions>
        <v-btn color="primary" :to="{ name: `${resource}-create` }">
          <v-icon size="small">fas fa-plus</v-icon>&nbsp;Add New
        </v-btn>
      </template>
    </v-banner>

    <ResourceTable :resource="resource" :headers="headers" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    resource: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style lang="scss">
.table-layout {
  .v-banner__wrapper {
    padding: 16px 0 !important;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 24px;
    line-height: 1.5;
  }

  .v-banner__text {
    font-size: 24px;
    line-height: 1.5;
  }
}
</style>
