<template>
  <div class="edit-layout">
    <h2 class="mb-5">
      {{ title }}
    </h2>

    <template v-if="record">
      <ValidationObserver ref="form" v-slot="{ valid, handleSubmit }" slim>
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-card :loading="loading" :disabled="loading">
            <div class="edit-layout__form">
              <slot
                name="form"
                v-bind="{
                  initRecord,
                  handleChange,
                  isSubmitting: loading,
                  valid,
                  changed,
                  record,
                  edit: true
                }"
              >
              </slot>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <slot name="actions">
                <v-btn type="submit" color="primary" :disabled="!changed"
                  >Update</v-btn
                >
              </slot>
              <slot name="actions">
                <v-btn color="error" @click="deleteAlertVisible = true"
                  >Delete</v-btn
                >
              </slot>
            </v-card-actions>
          </v-card>
        </v-form>

        <ConfirmDialog
          v-model="deleteAlertVisible"
          @handleClose="deleteAlertVisible = false"
          @handleConfirm="deleteResource"
        />
      </ValidationObserver>
    </template>

    <template v-else>
      <v-card loading>
        <div style="height: 25vh"></div>
      </v-card>
    </template>
  </div>
</template>

<script>
import deepEqual from "deep-equal";

export default {
  props: {
    resource: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    updateSuccessMessage: {
      type: String,
      default: "Updated successfully!"
    },
    deleteSuccessMessage: {
      type: String,
      default: "Successfuly deleted!"
    }
  },
  data() {
    return {
      loading: false,
      record: null,
      deleteAlertVisible: false,
      changed: false,
      initialRecord: null
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  created() {
    this.getResource();
  },
  watch: {
    record: {
      deep: true,
      handler(value) {
        if (!value) {
          return;
        }

        this.changed = !deepEqual(value, this.initialRecord, {
          strict: true
        });
      }
    }
  },
  methods: {
    getResource() {
      this.getOne(this.resource, { id: this.id })
        .then(res => {
          this.initialRecord = this._.cloneDeep(res.data);
          this.record = this._.cloneDeep(res.data);
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            this.$toasted.show("Not found!", {
              type: "error"
            });
            this.backToList();
          } else {
            this.$toasted.show(err.message, {
              type: "error"
            });
          }
        });
    },
    initRecord(record) {
      this.record = record;
    },
    handleChange(key, value) {
      this.record = this._.set(this.record, key, value);
    },
    backToList() {
      if (window.history.length > 0) {
        this.$router.back();
      } else {
        this.$router.push({
          name: `${this.resource}-list`
        });
      }
    },
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.update(this.resource, { id: this.id, data: this.record })
        .then(() => {
          this.loading = false;
          this.$toasted.show(this.updateSuccessMessage, {
            type: "success"
          });

          this.backToList();
        })
        .catch(err => {
          this.loading = false;

          if (err.response && err.response.status === 422) {
            this.$refs.form.setErrors(err.response.data.error);
          } else {
            this.$toasted.show(err.message, {
              type: "error"
            });
          }
        });
    },
    deleteResource() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.delete(this.resource, { id: this.id })
        .then(() => {
          this.loading = false;
          this.deleteAlertVisible = false;

          this.backToList();

          this.$toasted.show(this.deleteSuccessMessage, {
            type: "success"
          });
        })
        .catch(err => {
          this.loading = false;
          this.deleteAlertVisible = false;

          this.$toasted.show(err.message, {
            type: "error"
          });
        });
    }
  }
};
</script>
