/* eslint no-console:0 */

import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import path from "path";
import VueClipboard from "vue-clipboard2";

import { apiRoutes } from "./api_routes";
import globalMixin from "../../plugins/globalMixin";
import lodash from "lodash";
import VueLodash from "vue-lodash";

/* Vuetify */
import Vuetify from "vuetify";
import vuetify from "../../plugins/vuetify";

/* Vee Validate */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "../../plugins/veeValidate";

/* Toasted */
import Toasted from "vue-toasted";

const autoRegister = requireComponents => {
  requireComponents.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponents(fileName);
    const componentName = path.basename(fileName, ".vue");

    // Register component globally
    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};

export default (Component, routes, base = "") => {
  const globalComponents = require.context(
    "../../components",
    true,
    /[A-Z]\w+\.(vue|js)$/
  );

  const globalLayouts = require.context(
    "../../layouts",
    true,
    /[A-Z]\w+\.(vue|js)$/
  );

  autoRegister(globalComponents);
  autoRegister(globalLayouts);

  Vue.mixin(globalMixin);
  Vue.use(VueRouter);
  Vue.use(VueAxios, axios);
  Vue.use(VueLodash, { lodash });
  Vue.use(Vuetify);
  Vue.use(Toasted, {
    duration: 5 * 1000,
    position: "bottom-center"
  });
  Vue.use(VueClipboard);
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("ValidationProvider", ValidationProvider);

  Vue.prototype.$apiRoutes = apiRoutes;

  const token = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");

  axios.defaults.headers.common["X-CSRF-Token"] = token;

  const router = new VueRouter({
    mode: "history",
    base,
    routes
  });

  document.addEventListener("DOMContentLoaded", () => {
    new Vue({
      router,
      vuetify,
      render: createEl => createEl(Component)
    }).$mount("#app");
  });
};
